.inputBox.inputBox {
  width: initial;
  height: 52px;
  flex: 1;
}
.BoxInputStyleBoxInactive.BoxInputStyleBoxInactive.BoxInputStyleBoxInactive {
  height: initial;
  flex: 1;
}
.InputMasterBox.InputMasterBox.InputMasterBox.InputMasterBox {
  height: initial;
  flex: 1;
}
.labelFrame.labelFrame.labelFrame.labelFrame {
  height: initial;
  flex: 1;
}
.inputFrame.inputFrame.inputFrame.inputFrame {
  height: initial;
  flex: 1;
  border-radius: 6px 0 0 6px;
}
.placeholder {
  color: #9ca3af;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.ButtonMasterLarge.ButtonMasterLarge.ButtonMasterLarge.ButtonMasterLarge {
  border-radius: 0 6px 6px 0;
}
.label {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.avatar.avatar.avatar.avatar.avatar {
  background-image:/* webpackIgnore: true */ url('/assets/avatar13.jpg');
}
.avatar2.avatar2.avatar2.avatar2.avatar2 {
  background-image:/* webpackIgnore: true */ url('/assets/avatar14.jpg');
}
.avatar3.avatar3.avatar3.avatar3.avatar3 {
  background-image:/* webpackIgnore: true */ url('/assets/avatar15.jpg');
}
.avatar4.avatar4.avatar4.avatar4.avatar4 {
  background-image:/* webpackIgnore: true */ url('/assets/avatar16.jpg');
}
.avatar5.avatar5.avatar5.avatar5.avatar5 {
  background-image:/* webpackIgnore: true */ url('/assets/avatar17.jpg');
}
.initials {
  color: #8c2be3;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: min-content;
  text-align: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
}
.icon {
  width: 100%;
  height: 100%;
}
.button.button {
  left: 33px;
}
.ButtonMasterSmall.ButtonMasterSmall.ButtonMasterSmall.ButtonMasterSmall {
  outline: solid 1px #7022b6;
  background-color: #1c092d;
}
.label2 {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.mediaIcon.mediaIcon {
  left: 10.333px;
  top: 10px;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.label3 {
  color: #8c2be3;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.mediaIcon2.mediaIcon2 {
  left: 10.333px;
  top: 10px;
}
.icon3 {
  width: 100%;
  height: 100%;
}
.label4 {
  color: #8c2be3;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.mediaIcon3.mediaIcon3 {
  left: 10.333px;
  top: 10px;
}
.icon4 {
  width: 100%;
  height: 100%;
}
.label5 {
  color: #8c2be3;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.mediaIcon4.mediaIcon4 {
  left: 10.333px;
  top: 10px;
}
.icon5 {
  width: 100%;
  height: 100%;
}
.label6 {
  color: #8c2be3;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.mediaIcon5.mediaIcon5 {
  left: 10.333px;
  top: 10px;
}
.icon6 {
  width: 100%;
  height: 100%;
}
.label7 {
  color: #8c2be3;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.mediaIcon6.mediaIcon6 {
  left: 10.333px;
  top: 10px;
}
.icon7 {
  width: 100%;
  height: 100%;
}
.label8 {
  color: #8c2be3;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.inputBox2.inputBox2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 592px;
  height: 52px;
}
.BoxInputStyleBoxInactive2.BoxInputStyleBoxInactive2.BoxInputStyleBoxInactive2 {
  height: initial;
  flex: 1;
}
.InputMasterBox2.InputMasterBox2.InputMasterBox2.InputMasterBox2 {
  height: initial;
  flex: 1;
}
.labelFrame2.labelFrame2.labelFrame2.labelFrame2 {
  height: initial;
  flex: 1;
}
.inputFrame2.inputFrame2.inputFrame2.inputFrame2 {
  height: initial;
  flex: 1;
}
.placeholder2 {
  color: #9ca3af;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.button2.button2 {
  position: absolute;
  left: 400px;
  top: 0;
}
.label9 {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}

.hero {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: min-content;
  align-items: center;
  gap: 32px;
  padding: 160px 112px;
  background-color: #1c092d;
  overflow: hidden;
  box-shadow: 0px 24px 48px -12px #1018280d;
}
.frame12 {
  height: min-content;
  flex: 1;
}
.frame9 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  gap: 32px;
}
.hireFasterAndSmarterThanYourCo {
  color: #fff;
  font-size: 52px;
  line-height: 64px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.frame15 {
  height: min-content;
  align-self: stretch;
}
.hireTheBestBeforeTheyTakeUpAno {
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.labelWrapper {
  font-size: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label10 {
  color: #e6e5e6;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.label11 {
  color: #e6e5e6;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.frame8 {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  padding: 1px 0;
}
.frame33 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 20px;
}
.haveStartedInterviewing {
  color: #6b7280;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 542px;
  height: min-content;
  flex-direction: column;
}
.frame151 {
  position: relative;
  width: 590px;
  height: 405px;
  align-items: flex-start;
}

.frame14 {
  position: absolute;
  left: calc(50% - 0.292px);
  top: calc(50% - -0.4261px);
  width: 229.416px;
  height: 235.8522px;
  transform: translate(-50%, -50%);
}
.frame142 {
  transform-origin: top left;
  position: absolute;
  left: 0;
  top: 16.5144px;
  width: 213px;
  height: 220px;
  place-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #1c092dcc;
  box-shadow: 0px 24px 48px -12px #10182840, inset 0px 1px 4px 0px #ffffff40, inset 0px 1px 2px 0px #ffffff40,
    inset 0px 0px 36px 0px #ffffff1a;
  backdrop-filter: blur(6px);
  transform: matrix(0.997, -0.0775, 0.0775, 0.997, 0, 0);
}
.frame116 {
  position: absolute;
  left: 6.5px;
  top: 15.5px;
  width: 200px;
  height: 189px;
  align-items: flex-start;
}
.group13 {
  position: absolute;
  left: -13.5px;
  top: -13.0001px;
  width: 226.0002px;
  height: 226.0001px;
  overflow: visible;
}
.icon8 {
  width: 100%;
  height: 100%;
}
.rectangle {
  opacity: 0.2;
  transform-origin: top left;
  position: absolute;
  left: -3.1339px;
  top: -12.475px;
  width: 205.9196px;
  height: 213.1023px;
  border-radius: 15px;
  background-image:/* webpackIgnore: true */ url('/assets/rectangle.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: matrix(1, -0.0001, 0.0001, 1, 0, 0);
}
.frame117 {
  position: absolute;
  left: 65px;
  top: 58px;
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: center;
}
._94 {
  color: #f9fafb;
  font-size: 52px;
  line-height: 64px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.score {
  color: #9ca3af;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: min-content;
  place-content: space-between;
  align-items: center;
  padding: 20px 32px;
  background-color: #1c092de5;
  overflow: hidden;
}
.frame27 {
  width: min-content;
  height: min-content;
  align-items: center;
  gap: 12px;
}

.nav {
  width: min-content;
  height: min-content;
  align-items: flex-start;
  gap: 32px;
}
.templates {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.pricing {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.features {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.blog {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.frame32 {
  position: relative;
  width: 117px;
  height: 40px;
  align-items: flex-start;
}

.line6 {
  width: 1216px;
  max-width: 100%;
  height: 0;
  max-height: 100%;
  border-bottom: solid 1px #e5e7eb;
  margin-bottom: -1px;
}
.featureBreakdown {
  /* width: 1216px; */
  height: min-content;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  padding: 100px 296px;
  overflow: hidden;
}
.title {
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.byInterviewersForInterviewers {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.interviewingDoesNotHaveToBeStr {
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.textBlock {
  color: #111827;
  font-size: 36px;
  line-height: 44px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
}
.textBlock2 {
  margin-top: 0;
  color: #111827;
  font-size: 36px;
  line-height: 44px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
}
.features2 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}
.frame18 {
  width: 1216px;
  height: min-content;
  align-items: flex-start;
  gap: 32px;
  padding: 4px;
}
.frame17 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: flex-start;
  gap: 12px;
}
.frame20 {
  position: relative;
  width: 52px;
  height: 52px;
  align-items: flex-start;
}
.ellipse1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  height: 52px;
  overflow: visible;
}
.icon9 {
  width: 100%;
  height: 100%;
}
.centralizedTemplates {
  color: #1f2937;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.avoidTemplateDuplicatesAndInCo {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.frame202 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: flex-start;
  gap: 12px;
}
.frame21 {
  position: relative;
  width: 52px;
  height: 52px;
  align-items: flex-start;
}
.ellipse12 {
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  height: 52px;
  overflow: visible;
}
.icon10 {
  width: 100%;
  height: 100%;
}
.interviewerScore {
  color: #1f2937;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.takeTheHardWorkOutOfScoringCan {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.textBlock3 {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.textBlock4 {
  margin-top: 0;
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.frame19 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.frame203 {
  position: relative;
  width: 52px;
  height: 52px;
  align-items: flex-start;
}
.ellipse13 {
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  height: 52px;
  overflow: visible;
}
.icon11 {
  width: 100%;
  height: 100%;
}
.automatedReporting {
  color: #1f2937;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.summarizingInterviewNotesCanBe {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.textBlock5 {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.textBlock6 {
  margin-top: 0;
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.frame192 {
  width: 1216px;
  height: min-content;
  align-items: flex-start;
  gap: 32px;
  padding: 4px;
}
.frame193 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.frame212 {
  position: relative;
  width: 52px;
  height: 52px;
  align-items: flex-start;
}
.ellipse14 {
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  height: 52px;
  overflow: visible;
}
.icon12 {
  width: 100%;
  height: 100%;
}
.organizedWorkspace {
  color: #1f2937;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.keepAllYourInterviewingResourc {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.frame204 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.frame205 {
  position: relative;
  width: 52px;
  height: 52px;
  align-items: flex-start;
}
.ellipse15 {
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  height: 52px;
  overflow: visible;
}
.icon13 {
  width: 100%;
  height: 100%;
}
.liveCollaboration {
  color: #1f2937;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.streamlineYourWorkflowWithYour {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.frame182 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.frame213 {
  position: relative;
  width: 52px;
  height: 52px;
  align-items: flex-start;
}
.ellipse16 {
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  height: 52px;
  overflow: visible;
}
.icon14 {
  width: 100%;
  height: 100%;
}
._100InterviewTemplates {
  color: #1f2937;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.donTHaveTimeToFormInterviewQue {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.textBlock7 {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.textBlock8 {
  margin-top: 0;
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.featureBreakdown2 {
  width: 1216px;
  height: min-content;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  padding: 40px 296px 100px;
  overflow: hidden;
}
.frame26 {
  position: relative;
  width: 1006px;
  height: 80px;
  align-items: flex-start;
}
.line7 {
  position: absolute;
  left: -105px;
  top: 39px;
  width: 1216px;
  max-width: 100%;
  height: 0;
  max-height: 100%;
  border-bottom: solid 1px #d1d5db;
  margin-bottom: -1px;
}
.toggle {
  position: absolute;
  left: 0;
  top: 0;
  width: 1006px;
  height: min-content;
  border-radius: 12px;
  overflow: hidden;
}
.frame22 {
  height: min-content;
  flex: 1;
  align-self: flex-start;
  align-items: flex-start;
}
.frame24 {
  height: min-content;
  flex: 1;
  place-content: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: inset 0px -4px 0px 0px #a148f0;
}
.interviewers {
  color: #111827;
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.frame23 {
  height: min-content;
  flex: 1;
  place-content: center;
  padding: 20px;
  background-color: #fff;
}
.hiringManagers {
  color: #9ca3af;
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.frame28 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
}
.feature1 {
  width: 1006px;
  height: min-content;
  align-items: center;
  gap: 32px;
}
.frame172 {
  width: 487px;
  height: min-content;
  flex-direction: column;
  place-content: center;
  align-items: flex-start;
  gap: 12px;
}
.feelPreparedAndConfidentForEve {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.textBlock9 {
  color: #1f2937;
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
}
.textBlock10 {
  margin-top: 0;
  color: #1f2937;
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
}
.compareCandidateSPerformanceBa {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 382px;
  height: min-content;
  flex-direction: column;
}
.frame25 {
  position: relative;
  width: 484px;
  height: 352px;
  align-items: flex-start;
}
.image4 {
  position: absolute;
  left: 42px;
  top: 13px;
  width: 400px;
  height: 325.6px;
  background-image:/* webpackIgnore: true */ url('/assets/image4.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.feature2 {
  width: 1006px;
  height: min-content;
  align-items: center;
  gap: 32px;
}
.frame252 {
  position: relative;
  width: 590px;
  height: 352px;
  align-items: flex-start;
}
.image5 {
  position: absolute;
  left: 66px;
  top: 9px;
  width: 400px;
  height: 334.4px;
  background-image:/* webpackIgnore: true */ url('/assets/image5.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame173 {
  width: 382px;
  height: min-content;
  flex-direction: column;
  place-content: center;
  align-items: flex-start;
  gap: 12px;
}
.maintainAConsistentWorkflowWit {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.textBlock11 {
  color: #1f2937;
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
}
.textBlock12 {
  margin-top: 0;
  color: #1f2937;
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
}
.compareCandidateSPerformanceBa2 {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 382px;
  height: min-content;
  flex-direction: column;
}
.feature3 {
  width: 1006px;
  height: min-content;
  align-items: center;
  gap: 32px;
}
.frame174 {
  width: 487px;
  height: min-content;
  flex-direction: column;
  place-content: center;
  align-items: flex-start;
  gap: 12px;
}
.assessYourCandidatesFasterAndS {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.textBlock13 {
  color: #1f2937;
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
}
.textBlock14 {
  margin-top: 0;
  color: #1f2937;
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
}
.compareCandidateSPerformanceBa3 {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 382px;
  height: min-content;
  flex-direction: column;
}
.frame253 {
  position: relative;
  width: 484px;
  height: 352px;
  align-items: flex-start;
}
.image6 {
  position: absolute;
  left: 42px;
  top: 9px;
  width: 400px;
  height: 333.6px;
  background-image:/* webpackIgnore: true */ url('/assets/image6.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.feature4 {
  width: 1006px;
  height: min-content;
  align-items: center;
  gap: 32px;
}
.frame254 {
  position: relative;
  width: 590px;
  height: 352px;
  align-items: flex-start;
}
.image52 {
  position: absolute;
  left: 66px;
  top: 9px;
  width: 400px;
  height: 334.4px;
  background-image:/* webpackIgnore: true */ url('/assets/image52.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame175 {
  width: 382px;
  height: min-content;
  flex-direction: column;
  place-content: center;
  align-items: flex-start;
  gap: 12px;
}
.simplifyYourEntireInterviewPro {
  color: #1f2937;
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.compareCandidateSPerformanceBa4 {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 382px;
  height: min-content;
  flex-direction: column;
}
.frame176 {
  height: min-content;
  align-self: stretch;
  padding: 20px;
  outline: solid 2px #e5e7eb;
  outline-offset: -2px;
  border-radius: 8px;
  background-color: #fff;
}
.frame38 {
  z-index: 0;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.viewAllFeatures {
  color: #6b7280;
  font-size: 18px;
  line-height: 28px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.line72 {
  width: 1216px;
  max-width: 100%;
  height: 0;
  max-height: 100%;
  border-bottom: solid 1px #e5e7eb;
  margin-bottom: -1px;
}
.featureBreakdown3 {
  width: 1216px;
  height: min-content;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  padding: 100px 296px;
  overflow: hidden;
}
.title2 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.donTTakeOurWordForIt {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.hereIsWhatOurInterviewersHadTo {
  color: #111827;
  font-size: 36px;
  line-height: 44px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.features3 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}
.testimonials {
  width: 1216px;
  height: min-content;
  align-items: flex-start;
  gap: 32px;
}
.frame177 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: flex-start;
  gap: 12px;
  padding: 20px;
  background-color: #fff;
}
.frame206 {
  width: 52px;
  height: 52px;
  overflow: visible;
}
.icon15 {
  width: 100%;
  height: 100%;
}
.davidG {
  color: #1f2937;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.MaintainConsistencyThroughoutY {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.frame183 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 20px;
  background-color: #fff;
}
.frame207 {
  width: 52px;
  height: 52px;
  overflow: visible;
}
.icon16 {
  width: 100%;
  height: 100%;
}
.taylorW {
  color: #1f2937;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.MaintainConsistencyThroughoutY2 {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.frame194 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 20px;
  background-color: #fff;
}
.frame208 {
  width: 52px;
  height: 52px;
  overflow: visible;
}
.icon17 {
  width: 100%;
  height: 100%;
}
.michaelW {
  color: #1f2937;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.MaintainConsistencyThroughoutY3 {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.testimonials2 {
  width: 1216px;
  height: min-content;
  align-items: flex-start;
  gap: 32px;
}
.frame178 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: flex-start;
  gap: 12px;
  padding: 20px;
  background-color: #fff;
}
.frame209 {
  width: 52px;
  height: 52px;
  overflow: visible;
}
.icon18 {
  width: 100%;
  height: 100%;
}
.davidG2 {
  color: #1f2937;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.MaintainConsistencyThroughoutY4 {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.frame184 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 20px;
  background-color: #fff;
}
.frame2010 {
  width: 52px;
  height: 52px;
  overflow: visible;
}
.icon19 {
  width: 100%;
  height: 100%;
}
.taylorW2 {
  color: #1f2937;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.MaintainConsistencyThroughoutY5 {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.frame195 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 20px;
  background-color: #fff;
}
.frame2011 {
  width: 52px;
  height: 52px;
  overflow: visible;
}
.icon20 {
  width: 100%;
  height: 100%;
}
.michaelW2 {
  color: #1f2937;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.MaintainConsistencyThroughoutY6 {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.footer {
  position: absolute;
  left: 0;
  top: 4217px;
  width: 1440px;
  height: min-content;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  padding: 100px 112px;
  background-color: #111827;
  overflow: hidden;
}
.cTA {
  width: 592px;
  height: min-content;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.frame30 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
}
.joinOurWaitlist {
  color: #f9fafb;
  font-size: 52px;
  line-height: 64px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  width: 592px;
  height: min-content;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.getEarlyAccessToInterviewer {
  color: #6b7280;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.frame82 {
  position: relative;
  height: 52px;
  align-self: stretch;
  align-items: flex-start;
}
.line73 {
  width: 1216px;
  max-width: 100%;
  height: 0;
  max-height: 100%;
  border-bottom: solid 1px #374151;
  margin-bottom: -1px;
}
.content2 {
  width: 1216px;
  height: min-content;
  place-content: space-between;
  align-items: flex-start;
}
.frame179 {
  width: 382px;
  height: min-content;
  flex-direction: column;
  place-content: center;
  align-items: flex-start;
  gap: 20px;
}
.frame272 {
  width: min-content;
  height: min-content;
  align-items: center;
  gap: 12px;
}
.interviewer2 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.weHelpInterviewersTakeTheStres {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 382px;
  height: min-content;
  flex-direction: column;
}
.copyright2021Interviewer {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.frame31 {
  width: min-content;
  height: min-content;
  align-items: flex-start;
  gap: 32px;
}
.frame185 {
  width: 177px;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.useCases {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.frame34 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.interviewers2 {
  color: #e5e7eb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 177px;
  height: min-content;
  flex-direction: column;
}
.hiringManagers2 {
  color: #e5e7eb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 177px;
  height: min-content;
  flex-direction: column;
}
.startups {
  color: #e5e7eb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 177px;
  height: min-content;
  flex-direction: column;
}
.scaleups {
  color: #e5e7eb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 177px;
  height: min-content;
  flex-direction: column;
}
.enterprise {
  color: #e5e7eb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 177px;
  height: min-content;
  flex-direction: column;
}
.frame196 {
  width: 176px;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.company {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.frame35 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.aboutUs {
  color: #e5e7eb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 176px;
  height: min-content;
  flex-direction: column;
}
.contactUs {
  color: #e5e7eb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 176px;
  height: min-content;
  flex-direction: column;
}
.roadmap {
  color: #e5e7eb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 176px;
  height: min-content;
  flex-direction: column;
}
.pricing2 {
  color: #e5e7eb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 176px;
  height: min-content;
  flex-direction: column;
}
.blog2 {
  color: #e5e7eb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 176px;
  height: min-content;
  flex-direction: column;
}
.frame2012 {
  width: 175px;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.followUs {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.02em;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.frame36 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.linkedIn {
  color: #e5e7eb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 175px;
  height: min-content;
  flex-direction: column;
}
.twitter {
  color: #e5e7eb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 175px;
  height: min-content;
  flex-direction: column;
}
