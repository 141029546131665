.root {
  position: relative;
  width: 24px;
  height: 24px;
  align-items: flex-start;
  overflow: hidden;
}
.icon {
  position: absolute;
  left: 12.5%;
  right: 12.5%;
  top: 20.8333%;
  bottom: 15.1499%;
  overflow: visible;
}
.icon2 {
  width: 100%;
  height: 100%;
}
