.ButtonMasterMedium.ButtonMasterMedium {
  align-self: flex-start;
  padding: initial;
  background-color: initial;
}
.icon {
  width: 100%;
  height: 100%;
}
.label {
  color: #7022b6;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.root {
  width: min-content;
  height: min-content;
  flex-direction: column;
  overflow: hidden;
}
