.root {
  position: absolute;
  left: 0;
  top: 0;
  width: 32px;
  height: 32px;
  align-items: flex-start;
  overflow: hidden;
}
.icon {
  position: absolute;
  left: 14.4531%;
  right: 14.4531%;
  top: 9.375%;
  bottom: 9.375%;
  overflow: visible;
}
.icon2 {
  width: 100%;
  height: 100%;
}
