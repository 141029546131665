.iconLeft.iconLeft {
  width: 20px;
  height: 20px;
}
.icon {
  width: 100%;
  height: 100%;
}
.iconRight.iconRight {
  width: 20px;
  height: 20px;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.root {
  width: min-content;
  height: min-content;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 6px;
  background-color: #000;
  overflow: hidden;
}
.container {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  place-content: center;
  align-items: center;
  gap: 8px;
}
.label {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
